import { useState } from 'react';

import { NavLink } from 'react-router-dom';

import logo from '../assets/media/logo.png';
import open_menu from '../assets/media/open_menu.png';
import close_menu from '../assets/media/close_menu.png';

function Header() {

  const [menu, toggleMenu] = useState(false)

  return (
    <div className='navBar'>
      <div className='navBar__container'>
        <img src={logo} className='navBar__logo'/>
        <div className='navBar__links'>

        </div>
        <div className='navBar__container_mobileMenu'>
          {menu ?
            <button onClick={() => toggleMenu(false)} class='navBar__container_mobileMenu_img'>
              <img src={close_menu} class='navBar__container_mobileMenu_img'/>
            </button>
          :
            <button onClick={() => toggleMenu(true)}>
              <img src={open_menu} class='navBar__container_mobileMenu_img' />
            </button>
          }
        </div>
      </div>
      {menu &&
        <div class='navBar__container_mobileMenu_links'>
          <NavLink to={{pathname: '/'}}>
            <div className='navBar__link'>Tooted</div>
          </NavLink>
          <NavLink to={{pathname: '/categories'}}>
            <div className='navBar__link'>Kategooriad</div>
          </NavLink>
          <NavLink to={{pathname: '/help'}}>
            <div className='navBar__link'>Abi</div>
          </NavLink>
        </div>
      }
    </div>
  )
}

export default Header;

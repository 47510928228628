
import React, { Component } from "react";
import Header from '../components/header';
import Footer from '../components/footer';

import placeholder from '../assets/media/placeholder.png';
import { db, storage } from "../assets/services/firebase";

class Home extends Component {

  state = {
    image: null,
    imageName: null,
    url: '',
    progress: 0,
    categories: [],
    isLoading: true,
    categories: [],
    productsList: [],
    errors: [],
    selectedCategory: 0,
    heading: '',
    price: 0,
    description: '',
    newPrice: '',
    newDesc: '',
    deleteImage: ''
  }

  constructor() {
    super();
    this.returnProducts = this.returnProducts.bind(this)
  }

  componentDidMount() {
    this.getCategories()
  }

  getCategories() {
    db.ref('categories').on('value', snap => {
      let categories = []
      snap.forEach(child => {
        categories.push(child.val())
      })
      this.setState({categories: categories}, function() {
        this.getProductsList()
      })
    })

  }

  getProductsList() {
    this.setState({
      image: null,
      imageName: null,
      url: '',
      progress: 0,
      isLoading: true,
      productsList: [],
      errors: [],
      heading: '',
      price: 0,
      description: ''
    })
    db.ref('products').on('value', snap => {
      let productsList = []
      snap.forEach(child => {
        productsList.push({
          id: child.key,
          heading: child.val().heading,
          description: child.val().description,
          price: child.val().price,
          category: child.val().category,
          imageUrl: child.val().imageUrl,
          images: typeof child.val().images == 'undefined' ? [] : child.val().images
        })
      })
      console.log(productsList)
      this.setState({productsList: productsList, isLoading: false})
    })
  }

  handleChange = e => {
    if (e.target.files[0]) {
      console.log(e.target.files[0])
      this.setState({image: e.target.files[0]});
    }
  };

  handleAddChange = e => {
    if (e.target.files[0]) {
      this.setState({addingImage: e.target.files[0]});
    }
  };

  checkFields() {
    if(this.state.heading < 3 || this.state.price < 1) {
      this.setState({error: 'Väljad ei ole korrektselt täidetud'})
    } else {
      this.handleUpload()
    }
  }

  handleUpload = () => {
    const itemID = (new Date().getTime() + Math.floor(Math.random() * 10000) + 9999).toString()
    if(this.state.image == null) {
      db.ref('products').child(itemID).set({
        heading: this.state.heading,
        description: this.state.description,
        price: this.state.price,
        category: this.state.selectedCategory,
        imageUrl: this.state.url
      })
      this.getCategories()
      this.getProductsList()
    } else {
      const uploadTask = storage.ref(`images/${this.state.image.name}`).put(this.state.image);
      uploadTask.on(
        "state_changed",
        snapshot => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({progress: progress});
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref("images")
            .child(this.state.image.name)
            .getDownloadURL()
            .then(url => {
              this.setState({url: url})
              db.ref('products').child(itemID).set({
                heading: this.state.heading,
                description: this.state.description,
                price: this.state.price,
                category: this.state.selectedCategory,
              })
              db.ref('products').child(itemID).child('images').child(itemID).set({
                url: this.state.url,
                key: itemID,
                adid: itemID,
              })
              this.getCategories()
              this.getProductsList()
            });
        }
      );
    }
  };

  handleAddUpload = (id) => {
    const itemID = (Math.floor(Math.random() * 10000) + 9999).toString()
    const uploadTask = storage.ref(`images/${this.state.addingImage.name}`).put(this.state.addingImage);
    uploadTask.on(
      "state_changed",
      snapshot => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      error => {
        console.log(error);
      },
      () => {
        storage
          .ref("images")
          .child(this.state.addingImage.name)
          .getDownloadURL()
          .then(url => {
            this.setState({url: url})
            db.ref('products').child(id).child('images').child(itemID).set({
              url: this.state.url,
              key: itemID,
              adid: id,
            })
            this.getCategories()
            this.getProductsList()
          });
      }
    );
  };

  returnCategories() {
    let categoriesList = []
    for(let i=0; i<this.state.categories.length; i++) {
      categoriesList.push(
          <button key={i} className={this.state.selectedCategory == i ? 'homeContainer__addNewItem_selectCategory_item_selected' : 'homeContainer__addNewItem_selectCategory_item'} onClick={() => this.setState({selectedCategory: i})}>
            <text>{this.state.categories[i]}</text>
          </button>
      )
    }
    return categoriesList
  }

  returnImg(list) {
    console.log(typeof list)
    Object.keys(list).map(function(value, index) {
      return console.log(list[value].url);
    });
  }

  saveChanges(id, desc, price) {
    db.ref('products').child(id).update({
      price: this.state.newPrice.length > 0 ? this.state.newPrice : price,
      description: this.state.newDesc.length > 0 ? this.state.newDesc : desc
    })
    this.setState({changeItem: ''})
  }

  deleteItem(id) {
    db.ref('products').child(id).remove()
  }

  returnProducts(category) {
    let products = []
    let productsList = this.state.productsList
    for(let i=0; i<productsList.length; i++) {
      if(productsList[i].category == category) {
        products.push(
          <div key={i} className='shopContainer__productArea_item'>
            <div className='imageRow'>
              {productsList[i].images.length == 0 ?
                  <img key={i} src={placeholder} className='shopContainer__productArea_item_img' />
                :
                Object.keys(productsList[i].images).map((value, index) => {
                  return (
                    <img onClick={(list) => this.setState({deleteImage: productsList[i].images[value].key})} key={i} src={productsList[i].images[value].url} className='shopContainer__productArea_item_img' />
                  )
                })
              }
              <div className='addImage'>
                <input type="file" onChange={this.handleAddChange} style={{marginLeft: 10}}/>
                <button onClick={() => this.handleAddUpload(productsList[i].id)} className='changeButton w100'>Lisa</button>
              </div>
            </div>
            <div className='shopContainer__productArea_item_description'>
              <div style={{flexDirection: 'row'}}>
                <text className='shopContainer__productArea_item_description_boldText'>{productsList[i].heading}</text>
                <button onClick={() => this.setState({changeItem: productsList[i].id})} className='changeButton'>Muuda</button>
                {this.state.deleteItem == productsList[i].id ?
                  <button onClick={() => this.deleteItem(productsList[i].id)} className='deleteButton'>Kinnita</button>
                  :
                  <button onClick={() => this.setState({deleteItem: productsList[i].id})} className='deleteButton'>Kustuta</button>
                }
              </div>
              {this.state.changeItem == productsList[i].id ?
                <div className='infoArea'>
                  <text className='shopContainer__productArea_item_description_categoryText'>Kategooria: {this.state.categories[productsList[i].category]}</text>
                  <input className='changeDescription' onChange={(event) => this.setState({newDesc: event.target.value})} defaultValue={productsList[i].description} />
                  <input className='changePrice' onChange={(event) => this.setState({newPrice: event.target.value})} defaultValue={productsList[i].price}/>
                  <button onClick={() => this.saveChanges(productsList[i].id, productsList[i].description, productsList[i].price)} className='changeButton w100'>Kinnita</button>
                </div>
                :
                <div className='infoArea'>
                  <text className='shopContainer__productArea_item_description_categoryText'>Kategooria: {this.state.categories[productsList[i].category]}</text>
                  <text className='shopContainer__productArea_item_description_normalText'>{productsList[i].description}</text>
                  <text className='shopContainer__productArea_item_description_priceText'>{productsList[i].price} €</text>
                </div>
              }
            </div>
          </div>
        )
      }
    }
    if(products.length == 0) {
      return <text>Selles kategoorias tooted puuduvad</text>
    } else {
      return products
    }
  }

  render() {

    return (
      <div className="mainContainer">
        <Header />
        <div className='homeContainer'>
          <h1>Lisa uus toode</h1>
          <div className='homeContainer__addNewItem'>
            <div className='homeContainer__addNewItem_row'>
              <div className='homeContainer__addNewItem_row_heading'>
                <text>Vali pilt:</text>
              </div>
              <input type="file" onChange={this.handleChange} style={{marginLeft: 10}}/>
            </div>
            <div className='homeContainer__addNewItem_row'>
              <div className='homeContainer__addNewItem_row_heading'>
                <text>Pealkiri:</text>
              </div>
              <input value={this.state.heading} onChange={(event) => this.setState({heading: event.target.value})} style={{marginLeft: 10}}/>
            </div>
            <div className='homeContainer__addNewItem_row'>
              <div className='homeContainer__addNewItem_row_heading'>
                <text>Kirjeldus:</text>
              </div>
              <input value={this.state.description} onChange={(event) => this.setState({description: event.target.value})} style={{marginLeft: 10}}/>
            </div>
            <div className='homeContainer__addNewItem_row'>
              <div className='homeContainer__addNewItem_row_heading'>
                <text>Hind:</text>
              </div>
              <input value={this.state.price} onChange={(event) => this.setState({price: event.target.value})} style={{marginLeft: 10}}/> €
            </div>
            <div className='homeContainer__addNewItem_row'>
              <div className='homeContainer__addNewItem_row_heading'>
                <text>Kategooria:</text>
              </div>
              <div className='homeContainer__addNewItem_selectCategory'>
                {this.returnCategories()}
              </div>
            </div>
            {this.state.progress > 0 && this.state.progress < 100 ?
              <progress value={this.state.progress} max="100" />
            :
              <button onClick={() => this.checkFields()} class='greenButton'>{this.state.progress > 0 && this.state.progress < 100 ? 'Oota' : 'Salvesta'}</button>
            }

          </div>
          <div className='shopContainer__productArea'>
            {this.returnProducts(this.state.selectedCategory)}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
